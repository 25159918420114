import "./styles.scss"

import React from "react"

const Main = ({ data }) => {
  return (
    <section className="about-main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div
              className="about-main__content"
              dangerouslySetInnerHTML={{ __html: data?.aboutLeftContent }}
            />
          </div>
          <div className="col-md-5">
            <div
              className="about-main__content"
              dangerouslySetInnerHTML={{ __html: data?.aboutRightContent }}
            />
          </div>
          <div className="col-md-10">
            <img src={data?.aboutContentImage?.localFile?.publicURL} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
